import BaseApiService from "@/services/BaseApiService";

class ProductsApiService extends BaseApiService {

    async getCategoryProducts(url) {
        try {
            if (!url) throw Error("Invalid url!");
            const responseData = await this.get(url);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

}

export default new ProductsApiService();
