<template>
  <div>
    <router-view :configData="this.configData" :navData="this.navData"/>

    <!--footer-->
    <div class="container-fluid py-5 bg-blue">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 text-white">
            <img src="../assets/images/mainLogo.svg" class="footer-logo"/>
            <div class="text-yellow2 my-3">{{$t('contacts.title')}}</div>
            <div class="row">
              <div class="col-5">{{$t('contacts.hq')}}:</div>
              <div class="col-7">{{ this.configData.hq }}</div>
            </div>
            <div class="row">
              <div class="col-5">{{$t('contacts.dc')}}:</div>
              <div class="col-7">{{ this.configData.datacenter }}</div>
            </div>
            <div class="row">
              <div class="col-5">{{$t('contacts.phone')}}:</div>
              <div class="col-7">+{{ this.configData.phone }}</div>
            </div>
            <div class="text-yellow2 my-3">{{$t('contacts.cert')}}</div>
            <div class="row">
              <div class="col"><img src="../assets/images/ic-1.png"/></div>
              <div class="col"><img src="../assets/images/ic-2.png"/></div>
            </div>
          </div>
          <div class="col-lg-8">
            <div v-for="(items, key) in chunkedItems" :key="key" class="row">
              <div
                  v-for="(item, index) in items"
                  :key="index"
                  class="col-lg-4 text-white m-2"
              >
                <div class="">
                  <a
                      v-if="item.type === 'external'"
                      class="text-yellow2 text-decoration-none"
                      :href="item.url"
                  >{{ item.name }}</a
                  >
                  <router-link
                      v-else
                      :to="item.url"
                      class="text-yellow2 text-decoration-none"
                  >{{ item.name }}
                  </router-link>
                  <p class="mt-2">{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer-->

    <div class="container-fluid py-4 bg-yellow2 text-center text-white0 text-uppercase">
      SERVERGARDEN – COPYRIGHT @ 2005-2022 - {{$t('contacts.arr')}}
    </div>
  </div>
</template>

<script>
import TemplateApiService from "@/services/TemplateApiService";
import Localization from "@/utils/Localization";
import _ from "lodash";

export default {
  name: "Template",

  data() {
    return {
      navData: "",
      configData: ""
    };
  },
  beforeMount() {
    Localization.siteLocale();
    this.getSiteConfig();
    this.getNav();
  },
  methods: {
    async getNav() {
      await TemplateApiService.getNav().then((response) => {
        if (response) {
          this.navData = response.data;
          sessionStorage.navData = JSON.stringify(this.navData);
        }
      });
    },
    async getSiteConfig() {
      await TemplateApiService.getSiteConfig().then((response) => {
        if (response) {
          this.configData = response.data;
          sessionStorage.configData = JSON.stringify(this.configData);
        }
      });
    },
  },
  mounted() {

  },
  computed: {
    chunkedItems() {
      return _.chunk(this.navData.nav2, 2);
    },
  },
};
</script>

<style scoped></style>
