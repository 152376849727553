import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";

class Helpers {
    toggleCollapse(el) {
        let collapseElements = [].slice.call(el.querySelectorAll('.toggle'));
        collapseElements.map(function (e) {
            let dataId = e.getAttribute('data-id');
            let collapseElement = document.getElementById(dataId);
            let title = e.innerHTML;

            let plus = '<svg data-v-7e5e7457="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-plus-circle fa-w-16"><path data-v-7e5e7457="" fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" class=""></path></svg> ' + title;
            let minus = '<svg data-v-7e5e7457="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-minus-circle fa-w-16"><path data-v-7e5e7457="" fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z" class=""></path></svg> ' + title;

            e.innerHTML = plus;

            e.onclick = function () {
                if (collapseElement.style.display === 'block') {
                    collapseElement.style.display = 'none';
                    e.innerHTML = plus;
                } else {
                    collapseElement.style.display = 'block';
                    e.innerHTML = minus;
                }
            }
        });
    }

    createTooltip() {
        return Array.from(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    }


}

export default new Helpers();
