<template>
  <div>
    <header class="bg-subpage text-white mb-4" :style="{ backgroundImage: 'url(' + this.headerImage + ')' }">
      <div class="subpage-head">
        <Navbar :configData="this.configData" :navData="this.navData"/>
      </div>
    </header>

    <div class="container mt-5 mb-5 text-center">
      <h1>{{$t('other.thx')}}</h1>
    </div>
  </div>
</template>

<script>
import {SITE_TITLE} from "@/config";
import Navbar from "@/components/nav/Navbar";

export default {
  name: "Thanks",
  components: {
    Navbar
  },
  props: {
    configData: {data: [Array]},
    navData: {data: [Array]},
  },
  data() {
    return {
      name: "",
      description: "",
      products: [],
      metaTitle: SITE_TITLE,
      siteTitle: "",
      modules: null,
      headerImage: ""
    }
  },
  mounted() {
    this.setHb();
  },
  methods: {
    setHb(){
      if(this.$route.query.data){
        sessionStorage.hb=this.$route.query.data;
        window.location.href =  "/koszonjuk";
      }
      else if(sessionStorage.hb){
        window.ga('require', 'ecommerce');
        const hb=JSON.parse(sessionStorage.hb);
        window.ga('ecommerce:addTransaction', {"id":hb.id,"affiliation":"Servergarden Kft.","revenue":hb.revenue,"tax":hb.tax});
        hb.items.forEach(e => window.ga('ecommerce:addItem', {"id":hb.id,"name":e.name,"sku":e.sku,"category":e.category,"price":e.price,"quantity":e.quantity}));
        window.ga('ecommerce:send');

        var e = hb.items.map((function(t) {
          return {
            item_name: t.name,
            item_id: t.sku,
            item_category: t.category,
            price: t.price,
            quantity: t.quantity,
            currency: "HUF"
          }
        }));
        window.gtag("event", "purchase", {
          transaction_id: hb.id,
          affiliation: "Servergarden Kft.",
          value: hb.revenue.toFixed(2),
          tax: hb.tax,
          currency: "HUF",
          items: e
        });

        sessionStorage.removeItem('hb');
      }
      else{
        this.$router.push({ path: "/" });
      }
    }
  },
  metaInfo() {
    return {
      title: "Köszönjük megrendelését! | " + SITE_TITLE,
      meta: [
        {name: "robots", content: "noindex"},
      ],
    }
  }
}
</script>

<style scoped>

</style>
