<template>
  <div v-if="validationErrors">
    <div class="alert alert-danger list-group">
      <div class="list-group-item" v-for="(value, key) in validationErrors" v-bind:key="key">{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValidationError",
  props: ["errors"],
  computed: {
    validationErrors(){
      let errors = Object.values(this.errors);
      errors = errors.flat();
      return errors;
    }
  }
}
</script>

<style scoped>

</style>