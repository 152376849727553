import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faAngleUp, faMinusCircle, faPlusCircle, faChevronCircleRight, faQuoteRight, faAngleDown, faAngleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate'
import TemplatePlugin from '@/plugins/template-plugin'
import i18n from "./i18n";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap"

import "./assets/css/carousel.css"
import "./assets/css/style.css"
import "./assets/css/media.css"

Vue.config.productionTip = false

library.add(faSearch, faAngleUp, faMinusCircle, faPlusCircle, faChevronCircleRight, faQuoteRight, faAngleDown, faTimesCircle, faAngleRight, faArrowRight)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(TemplatePlugin)

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
