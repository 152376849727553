import {VUE_APP_I18N_LOCALE} from "@/config";
import i18n from "../i18n";

class Localization {

    setLocale(locale){
        sessionStorage.lang=locale;
        window.location.href="/";
    }
    siteLocale() {
        i18n.locale = sessionStorage.lang || VUE_APP_I18N_LOCALE;
        sessionStorage.lang = i18n.locale;
    }

    getLocale(){
        return sessionStorage.lang || VUE_APP_I18N_LOCALE;
    }
}

export default new Localization();
