<template>
  <li>
    <a v-if="this.data.type==='external'" :href="this.data.url" class="nav-link link2 submenu">{{ this.data.name }}</a>
    <span v-else-if="this.data.type==='separator'" class="nav-link link2 separator">{{ this.data.name }}</span>
    <router-link v-else class="nav-link link2" :to="this.data.url">{{ this.data.name }}</router-link>
  </li>
</template>

<script>
export default {
  name: "DropdownSubmenuItem",
  props: {
    data: {type: [Object], required: true}
  }
}
</script>

<style scoped>

</style>
