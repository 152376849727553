<template>
  <ul class="navbar-nav ms-auto">
    <li class="nav-item" v-for="(item, index) in (this.data)" :key="index">
      <div v-if="item.children.length > 0" class="dropdown" @mouseover="hover(index)" @mouseleave="leave">
        <a class="nav-link" @click.prevent.stop="toggle(index)" :class="{'show' : (isVisible === index)}" href="#"
           role="button" id=""
           data-bs-toggle="dropdown" aria-expanded="false">
          {{ item.name }}
        </a>
        <transition name="fade">
          <ul class="dropdown-menu" :class="{'show' : (isVisible === index)}" aria-labelledby="">
            <DropdownMenuItem v-for="(item, indexDropdown) in (item.children)" :key="indexDropdown" :itemIndex="index" :data="item"/>
          </ul>
        </transition>

      </div>
      <span v-else-if="item.type==='separator'" class="nav-link separator">{{ item.name }}</span>
      <a v-else-if="item.type==='external'" :href="item.url" class="nav-link" target="_blank">{{
          item.name
        }}</a>
      <router-link v-else class="nav-link" :to="item.url">{{ item.name }}</router-link>
    </li>
  </ul>
</template>

<script>

import DropdownMenuItem from "@/components/nav/DropdownMenuItem";

export default {
  name: "MenuItem",
  components: {DropdownMenuItem},
  props: {
    data: {type: [Array], required: true},
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    toggle(index) {
      if (this.isVisible === index) {
        this.isVisible = false;
      } else {
        this.isVisible = index;
      }
    },
    hover(index){
      this.isVisible = index;
    },
    leave(){
      this.isVisible = false;
    }
  }
};
</script>
