<template>
  <div>
    <preloader/>
    <header class="bg-light text-white">
      <div class="header-menu" id="header-menu">
        <Navbar :configData="this.configData" :navData="this.navData"/>
      </div>
      <div class="container">

        <div class="clearfix d-none">
          <div class="col float-start py-1"></div>
          <div class="col float-end">
            <div class="chat-absoulute">
              <div class="chat-bg px-2 chat">
                <div class="chat-alert">1</div>
                <div class="row">
                  <div class="chat-bal col col-auto m-1 py-2 "><img src="../../assets/images/5p.jpg"></div>
                  <div class="chat-kozep text-blue col py-2 mx-0 px-1 ">
                    <div class="fs-6 fw-bold">{{ $t('header.helptitle') }}</div>
                    <div class="fs-7">{{ $t('header.helptext') }}</div>
                  </div>
                  <div class="chat-jobb bg-blue col col-auto py-3 m-2"><img src="../../assets/images/chat-icon.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </header>

    <div v-for="item in (this.modules)" :key="item.module">
      <ModuleLoader :data="item.data" :type="item.module" :prefix="item.prefix" :configData="configData"/>
    </div>

  </div>
</template>

<script>
import {SITE_TITLE} from "@/config";
import CmsApiService from "@/services/CmsApiService";
import ModuleLoader from "@/components/ModuleLoader";
import Navbar from "@/components/nav/Navbar";
import Preloader from "@/components/Preloader";

export default {
  name: "Home",
  components: {
    ModuleLoader,
    Navbar,
    Preloader,
  },
  props: {
    configData: {data: [Array]},
    navData: {data: [Object]},
  },
  data() {
    return {
      data: "",
      metaTitle: SITE_TITLE,
      siteTitle: "",
      modules: null,
    }
  },
  metaInfo() {
    const mTitle = this.metaTitle;
    const mDescription = this.data.description;
    const mKeywords = this.data.keywords;
    return {
      title: mTitle + " | " + SITE_TITLE,
      meta: [
        {name: 'description', content: mDescription},
        {name: 'keywords', content: mKeywords}
      ],
      htmlAttrs: {
        lang: sessionStorage.lang ? sessionStorage.lang : 'hu'
      }
    }
  },

  mounted() {
    this.getData();
  },
  beforeCreate() {
    document.body.className = '';
  },
  methods: {
    async getData() {
      this.domain = window.location.href;
      await CmsApiService.getContent('home')
          .then(response => {
            if (response) {
              this.data = response.data.content;
              this.metaTitle = response.data.content.title;
              this.modules = response.data.modules;
            }
          });
    }
  },
}
</script>

<style scoped>

</style>
