import axios from "axios";
//import router from "@/router";
import {API_BASE_URL} from "@/config";
import Localization from "@/utils/Localization";

class BaseApiService {

    constructor() {
        axios.defaults.withCredentials = true;
        this.baseUrl=API_BASE_URL + Localization.getLocale() + '/api/';
    }

    setHeader(key, value) {
        axios.defaults.headers.common[key] = value;
    }

    get(url = "") {
        return axios.get(this.baseUrl + url);
    }

    post(url, params, options = {}) {
        return axios.post(this.baseUrl + url, params, options);
    }

    put(url, params) {
        return axios.put(this.baseUrl + url, params);
    }

    patch(url, params) {
        return axios.patch(this.baseUrl + url, params);
    }

    delete(url) {
        return axios.delete(this.baseUrl + url);
    }

    handleErrors(e) {
        if (e.response) {
            const status = e.response.status;
            if (status === 404) {
                window.location.replace('/404');
            } else if (status === 422) {
                return e.response;
            } else {
                return 'Authentication failed!';
            }
        }

    }

    csrf() {
        return axios.get(API_BASE_URL + 'sanctum/csrf-cookie');
    }
}

export default BaseApiService;
