import BaseApiService from "@/services/BaseApiService";

class CrmApiService extends BaseApiService {
    async postContactForm(form) {
        try {
            const responseData = await this.post('contact-form', form);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

    async postUnsubscribeForm(form) {
        try {
            const responseData = await this.post('unsubscribe-form', form);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }
}

export default new CrmApiService();