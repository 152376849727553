var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('preloader'),_c('header',{staticClass:"bg-subpage text-white mb-4",style:({ backgroundImage: 'url(' + this.headerImage + ')' })},[_c('div',{staticClass:"subpage-head"},[_c('Navbar',{attrs:{"configData":this.configData,"navData":this.navData}})],1),_c('div',{staticClass:"container"},[_c('h1',{staticClass:"col-12 text-center my-5 d-inline-block"},[_vm._v(" "+_vm._s(this.data.title)+" ")])])]),_c('div',{staticClass:"container py-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 offset-lg-2",attrs:{"id":"article-detail"}},[(this.data.image !== null)?_c('img',{staticClass:"img-fluid",staticStyle:{"object-fit":"cover"},attrs:{"src":this.data.image,"alt":this.data.title}}):_vm._e(),_c('div',{staticClass:"text-blue fs-6 py-2"},[_c('span',[_vm._v(_vm._s(_vm.createDate(this.data.date, "MMM DD. YYYY")))])]),_c('Html',{attrs:{"data":this.data.text}})],1)])]),_c('h1',{staticClass:"col-12 text-center my-4 text-blue"},[_vm._v(_vm._s(_vm.$t('modules.morenews')))]),_c('div',{staticClass:"container py-4"},[_c('div',{staticClass:"row"},_vm._l((this.related),function(item,index){return _c('div',{key:index,staticClass:"col-lg-4"},[_c('router-link',{attrs:{"to":_vm.prefix +
            '/' +
            _vm.createDate(item.date, 'YYYY/MM/DD') +
            '/' +
            item.link}},[(item.image !== null)?_c('img',{staticClass:"img-fluid",staticStyle:{"height":"200px","object-fit":"cover"},attrs:{"src":item.image,"alt":item.title}}):_vm._e()]),_c('div',{staticClass:"clearfix py-3"},[_c('div',{staticClass:"col col float-start text-blue fs-6 py-2"},[_vm._v(" "+_vm._s(_vm.createDate(item.date, "YYYY.MM.DD"))+" ")])]),_c('div',{staticClass:"text-blue fw-bold fs-5"},[_c('router-link',{staticClass:"text-blue text-decoration-none",attrs:{"to":_vm.prefix +
            '/' +
            _vm.createDate(item.date, 'YYYY/MM/DD') +
            '/' +
            item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('div',{staticClass:"text-blue fs-6 py-2",domProps:{"innerHTML":_vm._s(item.lead)}}),_c('router-link',{staticClass:"text-decoration-none px-0 py-0 text-yellow list-date tovabbBtn",attrs:{"to":_vm.prefix +
            '/' +
            _vm.createDate(item.date, 'YYYY/MM/DD') +
            '/' +
            item.link}},[_vm._v(_vm._s(_vm.$t('modules.along'))+" "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-right']}})],1)],1)}),0)]),_c('div',{staticClass:"container px-0 text-center my-1"},[_c('a',{staticClass:"btn bg-blue2 fw-medium text-white my-4 px-5 py-2",attrs:{"href":_vm.prefix,"type":"button"}},[_vm._v(_vm._s(_vm.$t('modules.morenews')))])]),_vm._l((this.modules),function(item){return _c('div',{key:item.module},[_c('ModuleLoader',{attrs:{"data":item.data,"type":item.module,"prefix":item.prefix,"configData":_vm.configData}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }