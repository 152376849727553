<template>
  <div>
    <div v-html="this.data"/>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";

export default {
  name: "Html",
  props: {
    data: {data: [Array]},
  },
  mounted() {
    Helpers.toggleCollapse(this.$el);
  },
  updated() {
    Helpers.toggleCollapse(this.$el);
  }
}
</script>

<style scoped>

</style>