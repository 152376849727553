<template>
  <li>
    <div v-if="this.data.children.length > 0" @mouseover="hover(index)" @mouseleave="leave" class="position-relative">
      <a class="nav-link link2 dropdown-item dropdown-toggle" @click.prevent.stop="toggle(index)" :class="{'show' : (isVisible === index)}"
         href="#"
         role="button" id=""
         data-bs-toggle="dropdown" aria-expanded="false">
        {{ this.data.name }}
      </a>
      <transition name="fade">
        <ul class="dropdown-menu submenu" :class="{'show' : (isVisible === index), 'submenu-right' : (dropdownRight)}" aria-labelledby="">
          <DropdownSubmenuItem v-for="(item, index) in (this.data.children)" :key="index" :data="item"/>
        </ul>
      </transition>
    </div>
    <span v-else-if="this.data.type==='separator'" class="nav-link link2 separator">{{ this.data.name }}</span>
    <a v-else-if="this.data.type==='external'" :href="this.data.url" class="nav-link link2">{{ this.data.name }}</a>
    <router-link v-else class="nav-link link2" :to="this.data.url">{{ this.data.name }}</router-link>
  </li>
</template>

<script>
import DropdownSubmenuItem from "@/components/nav/DropdownSubmenuItem";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "DropdownMenuItem",
  components: {DropdownSubmenuItem},
  props: {
    data: {type: [Object], required: true},
    itemIndex: {type: [Number], required: true}
  },
  data() {
    return {
      isVisible: false,
      index: null,
      dropdownRight: false
    }
  },
  methods: {
    toggle(index) {
      if (this.isVisible === index) {
        this.isVisible = false;
      } else {
        this.isVisible = index;
      }
    },
    hover(index) {
      this.isVisible = index;
    },
    leave() {
      this.isVisible = false;
    }
  },
  mounted() {
    this.index = uuidv4();
    this.dropdownRight= (this.itemIndex > 2) ;
  }
}
</script>

<style scoped>

</style>
