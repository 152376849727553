<template>
  <div>
    <preloader />
    <header
      class="bg-subpage text-white mb-4"
      :style="{ backgroundImage: 'url(' + this.headerImage + ')' }"
    >
      <Navbar :configData="this.configData" :navData="this.navData" />
      <!--carousel-->
      <div class="container">
        <h1 class="col-12 text-center my-5 d-inline-block" ref="menuCollapse">
          {{ this.data.title }}
        </h1>
      </div>
    </header>
    <div v-for="item in this.modules" :key="item.module">
      <ModuleLoader
        :data="item.data"
        :type="item.module"
        :prefix="item.prefix"
        :configData="configData"
      />
    </div>
  </div>
</template>

<script>
import ModuleLoader from "@/components/ModuleLoader";
import { SITE_TITLE } from "@/config";
import CmsApiService from "@/services/CmsApiService";
import Navbar from "@/components/nav/Navbar";
import Preloader from "@/components/Preloader.vue";

export default {
  name: "Content",
  components: {
    ModuleLoader,
    Navbar,
    Preloader,
  },
  props: {
    configData: { data: [Array] },
    navData: { data: [Array] },
  },
  data() {
    return {
      data: "",
      metaTitle: SITE_TITLE,
      siteTitle: "",
      modules: null,
      headerImage: "",
    };
  },
  metaInfo() {
    const mTitle = this.metaTitle;
    const mDescription = this.data.description;
    const mKeywords = this.data.keywords;
    return {
      title: mTitle + " | " + SITE_TITLE,
      meta: [
        { name: "description", content: mDescription },
        { name: "keywords", content: mKeywords },
      ],
      htmlAttrs: {
        lang: sessionStorage.lang ? sessionStorage.lang : 'hu'
      }
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  beforeCreate() {
    document.body.className = "bg-white";
  },
  methods: {
    async getData() {
      this.domain = window.location.href;
      await CmsApiService.getContent("content/" + this.$route.params.slug).then(
        (response) => {
          if (response) {
            this.data = response.data.content;
            this.metaTitle = response.data.content.title;
            this.modules = response.data.modules;
            this.headerImage = this.$setHeaderImage(
              response.data.content.image
            );
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
