<template>
  <div>
    <div class="fixed-top" :class="{ 'header-white': this.sticky }">
      <div class="container" :class="{ 'bg-white': bg() }">
        <div class="navbar justify-content-between topbar">
          <router-link :to="'/'">
            <img
                class="m-2 img-fluid logo"
                src="../../assets/images/logo.svg"
            />
          </router-link>
          <div class="d-none d-lg-inline-block">
            <div class="form-inline">
              <div class="col-lg-auto" v-if="this.configData">
                <a :href="'tel:+' + this.configData.phone" class="mx-2 top-menu"
                >+{{ this.configData.phone }}</a
                >
                <a
                    :href="'mailto:' + this.configData.email"
                    class="mx-2 top-menu"
                >{{ this.configData.email }}</a
                >
              </div>
              <div class="search-form-wrapper" v-show="visible.searchVisible">
                <form
                    class="search-form"
                    ref="search-form"
                    action=""
                    @submit.prevent="redirectToResults"
                >
                  <div class="navBarInput-group">
                    <input
                        ref="searchInput"
                        v-model="$v.form.search.$model"
                        type="text"
                        name="search"
                        maxlength="100"
                        class="navBarInput-yellow search-input"
                        :class="{ invalidSearch: $v.form.search.$error }"
                        :placeholder="$t('header.search')"
                    />
                    <button
                        class="btn text-uppercase fw-bold navbarSearch"
                        type="submit"
                    >
                      <font-awesome-icon :icon="['fas', 'arrow-right']"/>
                    </button>
                  </div>
                </form>
              </div>
              <div class="col-lg-auto">
                <a
                    href="#search"
                    class="mx-2 top-menu mob-hide"
                    data-toggle="search-form"
                    @click.prevent.stop="searchToggle"
                >
                  <span class="fas-icon"
                  ><font-awesome-icon :icon="['fas', 'search']"
                  /></span>
                </a>
                <span class="mx-2">
                  <a
                      href="https://www.servergarden.hu/clientarea/"
                      class="top-menu"
                  >{{ $t('header.signin') }}</a
                  >
                  /
                  <a href="https://www.servergarden.hu/signup/" class="top-menu"
                  >{{ $t('header.signup') }}</a
                  >
                  </span>
<!--                <div class="dropdown d-none d-lg-inline-block">
                  <button class="btn dropdown-toggle text-uppercase" type="button" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                    {{ this.lang }}
                  </button>
                  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                    <li v-for="(item, index) in this.languages" :key="index">
                      <a @click.prevent.stop="setLanguage(item)"
                         class="dropdown-item text-uppercase"
                         rel="alternate"
                         :hreflang="item"
                         href="#">{{ item }}</a>
                    </li>
                  </ul>
                </div>-->

              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="menu-wrapper">
        <div class="container" :class="{ 'bg-white': bg() }">
          <div class="navbar-light">
            <button
                @click.prevent.stop="toggle"
                class="navbar-toggler"
                type="button"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <nav class="navbar navbar-expand-lg navbar-light">
            <div
                class="navbar-collapse"
                id="navbarSupportedContent"
                v-show="showCollapse"
            >
              <div class="nav-item d-block d-lg-none">
<!--                <div class="dropdown">
                  <button class="btn dropdown-toggle text-uppercase" type="button" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                    {{ this.lang }}
                  </button>
                  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                    <li v-for="(item, index) in this.languages" :key="index">
                      <a @click.prevent.stop="setLanguage(item)"
                         class="dropdown-item text-uppercase"
                         rel="alternate"
                         :hreflang="item"
                         href="#">{{ item }}</a>
                    </li>
                  </ul>
                </div>-->
              </div>
              <div class="nav-item d-block d-lg-none">
                <a href="https://www.servergarden.hu/clientarea/"
                   class="top-menu">{{ $t('header.signin') }}</a>
                /
                <a href="https://www.servergarden.hu/signup/" class="top-menu">{{ $t('header.signup') }}</a>
              </div>

              <MenuItem v-if="this.navData" :data="this.navData.nav1"/>

              <div class="nav-item d-block d-lg-none">
                <div class="search-form-wrapper">
                  <form
                      class="search-form mx-auto d-inline-block"
                      ref="search-form"
                      action=""
                      @submit.prevent="redirectToResults"
                  >
                    <div class="input-group">
                      <input
                          v-model="$v.form.search.$model"
                          type="text"
                          name="search"
                          maxlength="100"
                          class="bg-yellow search-input"
                          :class="{ invalidSearch: $v.form.search.$error }"
                          :placeholder="$t('header.search')"
                      />
                      <button
                          class="btn bg-yellow text-uppercase fw-bold"
                          type="submit"
                      >
                        <font-awesome-icon :icon="['fas', 'arrow-right']"/>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div
                  class="nav-item d-block d-lg-none no-border"
                  v-if="this.configData"
              >
                <a :href="'tel:+' + this.configData.phone" class="top-menu"
                >+{{ this.configData.phone }}</a
                >
                <br/>
                <a :href="'mailto:' + this.configData.email" class="top-menu">{{
                    this.configData.email
                  }}</a>
              </div>
              <div class="nav-item">
                <button
                    type="button"
                    class="btn bg-yellow text-uppercase fw-bold"
                    @click.prevent.stop="contactToggle"
                >
                  {{ $t('header.interested') }}
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div v-show="this.visible.contactVisible">
      <GetInTouch @toggle="contactToggle"/>
    </div>
  </div>
</template>

<script>
import MenuItem from "@/components/nav/MenuItem";
import {required, minLength} from "vuelidate/lib/validators";
import GetInTouch from "../partials/GetInTouch";
import {LANGUAGES} from "@/config";
import Localization from "../../utils/Localization";

export default {
  name: "Navbar",
  components: {
    GetInTouch,
    MenuItem,
  },
  props: {
    configData: {data: [Array]},
    navData: {data: [Array]},
  },
  data() {
    return {
      languages: LANGUAGES,
      lang: sessionStorage.lang,
      showCollapse: false,
      sticky: false,
      visible: {
        contactVisible: false,
        searchVisible: false,
      },
      disabledBtn: false,
      form: {
        search: null,
      },
    };
  },
  validations: {
    form: {
      search: {
        required,
        minLength: minLength(2),
      },
    },
  },
  watch: {
    $route() {
      this.showCollapse = false;
    },
  },
  methods: {
    redirectToResults() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      if (
          !this.$router.currentRoute.query.q ||
          this.$router.currentRoute.query.q !== this.form.search
      ) {
        this.$router.push({path: "/kereses", query: {q: this.form.search}});
      }
    },
    bg() {
      if (this.$route.path === "/") {
        return false;
      } else {
        return true;
      }
    },
    onScroll() {
      const currentScrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      if (currentScrollPosition > 0) {
        document.body.className = "scrolled";
        this.sticky = true;
      } else {
        document.body.className = "";
        this.sticky = false;
      }
    },
    toggle() {
      this.showCollapse = !this.showCollapse;
    },
    setFocus() {
      this.$refs.searchInput.focus();
    },
    searchToggle() {
      this.visible.searchVisible = !this.visible.searchVisible;
      if (this.visible.searchVisible) {
        this.$nextTick(() => {
          this.setFocus();
        });
      }
    },
    contactToggle() {
      this.visible.contactVisible = !this.visible.contactVisible;
      this.showCollapse = false;
    },
    setLanguage(locale) {
      Localization.setLocale(locale)
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
