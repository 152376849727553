<template>
  <component :is="component" :data="data" :prefix="prefix" v-if="component" :configData="configData" />
</template>
<script>
export default {
  name: 'ModuleLoader',
  props: {
    type: {type: [Number], required: true},
    data: {type: [Object, Array, String], required: true},
    prefix: {type: [String]},
    configData: {data: [Array]},
  },
  data() {
    return {
      component: null,
    }
  },
  computed: {
    loader() {
      return () => import(`@/components/partials/modules/module${this.type}`)
    }
  },
  mounted() {
    this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import("@/components/NotFound404")
        })
  }
}
</script>