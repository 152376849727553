import BaseApiService from "@/services/BaseApiService";

class CmsApiService extends BaseApiService {

    constructor() {
        super();
    }

    async getContent(url) {
        try {
            if (!url) throw Error("Invalid url!");
            const responseData = await this.get(url);
            return await responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

    async postSearch(expression) {
        try {
            if (!expression) throw Error("Invalid expression!");
            await this.csrf();
            const responseData = this.post('search', {
                'expression': expression,

            });
            return responseData;
        } catch (e) {
            return this.handleErrors(e);
        }
    }

}

export default new CmsApiService();
