<template>
  <div>
    <div class="my-4"/>
    <div class="bg-blue px-3 fomenu">
      <div class="clearfix">
        <div class="col float-start py-2">
          <div class="row product-list-title">
            <div class="col-md-auto fw-bold">
              <span class="text-white fas-icon">
              </span>
              {{ this.data.name }}
              <span class="d-block text-yellow fw-Light">{{
                  this.data.category_name
                }}</span>
            </div>
            <div class="col-md-auto menuel p-0 d-none">–</div>
          </div>
        </div>
        <div class="col float-end py-1">
          <div
              class="icon-c float-end"
              :class="{ 'icon-container': isVisible }"
          >
            <span
                class="discount circle fw-bold mx-1"
                v-if="this.data.discount > 0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('products.sale')"
                :title="$t('products.sale')"
            >{{ this.data.discount }}%</span
            >
            <span
                v-for="(item, index) in this.data.badges"
                :key="index"
                class="fw-bold d-none"
            >
              <img
                  class="icon mx-1"
                  :src="require(`@/assets/images/${item}`)"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$setTooltipTitle(item)"
                  :data-bs-original-title="$setTooltipTitle(item)"
              /></span>
            <span v-if="this.data.price > 0">
            <a :href="this.data.hostbill_url" type="button" class="btn bg-yellow fw-bold"
            >{{ $t('products.conf') }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse show" v-show="isVisible">
      <div class="card card-body">
        <div class="row">
          <div class="col-lg-12 pb-3 product-details">
            <div class="text-end">
              <div class="mb-2 text-white bg-blue-price text-uppercase ">
                <div v-if="this.data.discount > 0">
                  {{ $t('products.original') }} {{ $t('products.price') }}:
                  {{ Number(this.data.original_price).toLocaleString() }} {{ $t('products.currency') }} +
                  {{ $t('products.vat') }}
                  <span v-if="this.data.unit">/ {{ this.data.unit }}</span>
                </div>
                <span v-if="this.data.price > 0"
                ><span v-if="this.data.discount > 0">{{ $t('products.premium') }}</span> {{ $t('products.price') }}:
            {{ Number(this.data.price).toLocaleString() }} {{ $t('products.currency') }} + {{ $t('products.vat') }}
            <span v-if="this.data.unit">/ {{ this.data.unit }}</span></span
                >
                <span v-else data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-html="true"
                      title="Tel.: +36306824755<br />E-mail: sales@servergarden.hu"
                      data-bs-original-title="Tel.: +36306824755<br />E-mail: sales@servergarden.hu">
                 {{ $t('products.unique') }}</span>
              </div>
            </div>
            <Html
                class="text-blue d-none"
                :data="this.data.details"
                :containerId="'p1-' + this.id"
            />

            <div class="text-blue fw-bold mb-2">{{ $t('products.tdetails') }}</div>
            <div
                class="text-blue"
                v-html="this.data.description"
                style="line-height: 2em"
            />
          </div>
          <div class="col-lg-4 py-3 text-center d-none">
            <img
                :src="this.data.image"
                class="img-fluid"
                :alt="this.data.name"
                loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-blue px-3 py-3 text-uppercase">
      <div class="clearfix">
        <div class="col-md-9 text-white text-md-end fw-bold mb-3 mb-md-0">

        </div>
        <div class="col-md-3 text-end">
          <router-link
              :to="this.data.link"
              type="button"
              class="btn bg-yellow fw-bold position-relative"
          >{{ $t('products.details') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Html from "@/components/partials/Html";
import Helpers from "@/utils/Helpers";

export default {
  name: "ProductList",
  components: {Html},
  props: {
    data: {data: [Array]},
    index: {data: [Number]},
    open: {data: [Boolean]},
  },
  data() {
    return {
      isVisible: false,
      id: this._uid,
      showOther: false,
    };
  },
  mounted() {
    //if (this.index === 0 || this.open) {
    this.isVisible = true;
    this.showOther = true;
    // }
    Helpers.createTooltip();
  },
};
</script>

<style scoped></style>
