<template>
  <div>
    <preloader/>
    <header
        class="bg-subpage text-white mb-4"
        :style="{ backgroundImage: 'url(' + this.headerImage + ')' }"
    >
      <div class="subpage-head">
        <Navbar :configData="this.configData" :navData="this.navData"/>
      </div>
      <!--carousel-->
      <div class="container">
        <h1 class="col-12 text-center my-5 d-inline-block">{{ $t('search.title') }}</h1>
      </div>
    </header>
    <div class="container px-0 mt-5 mb-5">
      <div v-if="this.content.length > 0">
        <div
            v-for="(item, index) in this.content"
            :key="index"
            class="result-wrapper mb-3 pb-3"
        >
          <h5>{{ item.name }}</h5>
          <p v-html="item.text"/>
          <div v-if="item.prefix">
            <router-link
                :to="
                item.prefix +
                '/' +
                createDate(item.date, 'YYYY/MM/DD') +
                '/' +
                item.link
              "
                class="nav-link px-0 text-blue"
            >{{ $t('modules.along') }}
              <font-awesome-icon :icon="['fas', 'arrow-right']"
              />
            </router-link>
          </div>
          <div v-else>
            <router-link
                :to="'/' + item.link"
                class="nav-link px-0 text-blue tovabbBtn"
            >{{ $t('modules.along') }}
              <font-awesome-icon :icon="['fas', 'arrow-right']"
              />
            </router-link>
          </div>
        </div>
      </div>

      <div v-if="this.products.length > 0">
        <div
            v-for="(item, index) in this.products"
            :key="index"
            class="result-wrapper mb-3 pb-3"
        >
          <h5>{{ item.name }}</h5>
          <p v-html="item.text"/>
          <div>
            <router-link
                :to="item.link"
                class="nav-link px-0 text-blue tovabbBtn"
            >{{ $t('modules.along') }}
              <font-awesome-icon :icon="['fas', 'arrow-right']"
              />
            </router-link>
          </div>
        </div>
      </div>

      <div v-if="this.faq.length > 0">
        <div
            v-for="(item, index) in this.faq"
            :key="index"
            class="result-wrapper mb-3 pb-3"
        >
          <h5>{{ item.name }}</h5>
          <p v-html="item.text"/>
          <div>
            <router-link
                :to="item.link"
                class="nav-link px-0 text-blue tovabbBtn"
            >{{ $t('modules.along') }}
              <font-awesome-icon :icon="['fas', 'arrow-right']"
              />
            </router-link>
          </div>
        </div>
      </div>

      <div
          v-if="
          this.content.length === 0 &&
          this.products.length === 0 &&
          this.faq.length === 0
        "
      >
        <h5 class="text-center mt-5 mb-5">{{ $t('search.noresults') }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/nav/Navbar";
import {SITE_TITLE} from "@/config";
import CmsApiService from "@/services/CmsApiService";
import moment from "moment";
import Preloader from "@/components/Preloader";

export default {
  name: "Search",
  components: {
    Navbar,
    Preloader,
  },
  props: {
    configData: {data: [Array]},
    navData: {data: [Object]},
  },
  data() {
    return {
      data: "",
      content: "",
      products: "",
      faq: "",
      headerImage: "",
    };
  },
  metaInfo() {
    return {
      title: this.$t('search.title') + " | " + SITE_TITLE,
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.headerImage = this.$setHeaderImage(null);
  },
  methods: {
    async getData() {
      await CmsApiService.postSearch(this.$route.query.q).then((response) => {
        if (response) {
          this.data = response.data;
          this.content = response.data.content;
          this.products = response.data.products;
          this.faq = response.data.faq;
          this.metaTitle = response.data.siteTitle;
        }
      });
    },
    createDate(date, format) {
      return moment(date).format(format);
    },
  },
  beforeCreate() {
    document.body.className = "inner";
  },
};
</script>

<style scoped></style>
