<template>
  <div>
    <preloader/>
    <header
        class="bg-subpage text-white mb-4"
        :style="{ backgroundImage: 'url(' + this.headerImage + ')' }"
    >
      <div class="subpage-head">
        <Navbar :configData="this.configData" :navData="this.navData"/>
      </div>
      <!--carousel-->
      <div class="container">
        <h1 class="col-12 text-center my-5 d-inline-block">
          {{ this.data.title }}
        </h1>
      </div>
    </header>
    <div class="container py-4">
      <div class="row">
        <div class="col-lg-8 offset-lg-2" id="article-detail">
          <img
              v-if="this.data.image !== null"
              class="img-fluid"
              style="object-fit: cover"
              v-bind:src="this.data.image"
              v-bind:alt="this.data.title"
          />
          <div class="text-blue fs-6 py-2">
            <span>{{
                createDate(this.data.date, "MMM DD. YYYY")
              }}</span>
          </div>
          <Html :data="this.data.text"/>
        </div>
      </div>
    </div>
    <h1 class="col-12 text-center my-4 text-blue">{{$t('modules.morenews')}}</h1>

    <div class="container py-4">
      <div class="row">
        <div
            class="col-lg-4"
            v-for="(item, index) in this.related"
            :key="index"
        >
          <router-link
              :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
          >
            <img
                v-if="item.image !== null"
                class="img-fluid"
                style="height: 200px; object-fit: cover"
                v-bind:src="item.image"
                v-bind:alt="item.title"
            />
          </router-link>
          <div class="clearfix py-3">
            <div class="col col float-start text-blue fs-6 py-2">
              {{ createDate(item.date, "YYYY.MM.DD") }}
            </div>
          </div>
          <div class="text-blue fw-bold fs-5">
            <router-link
                :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
                class="text-blue text-decoration-none"
            >
              {{ item.title }}
            </router-link>
          </div>
          <div class="text-blue fs-6 py-2" v-html="item.lead"/>
          <router-link
              :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
              class="text-decoration-none px-0 py-0 text-yellow list-date tovabbBtn"
          >{{$t('modules.along')}} <font-awesome-icon :icon="['fas', 'arrow-right']"
          /></router-link>
        </div>
      </div>
    </div>

    <div class="container px-0 text-center my-1">
      <a
          :href="prefix"
          type="button"
          class="btn bg-blue2 fw-medium text-white my-4 px-5 py-2"
      >{{$t('modules.morenews')}}</a
      >
    </div>

    <div v-for="item in this.modules" :key="item.module">
      <ModuleLoader
          :data="item.data"
          :type="item.module"
          :prefix="item.prefix"
          :configData="configData"
      />
    </div>
  </div>
</template>

<script>
import CmsApiService from "@/services/CmsApiService";
import {SITE_TITLE} from "@/config";
import ModuleLoader from "@/components/ModuleLoader";
import moment from "moment";
import Navbar from "@/components/nav/Navbar";
import Html from "@/components/partials/Html";
import Preloader from "@/components/Preloader.vue";

export default {
  name: "ArticleDetail",
  components: {
    ModuleLoader,
    Preloader,
    Navbar,
    Html,
  },
  props: {
    configData: {data: [Array]},
    navData: {data: [Array]},
  },
  data() {
    return {
      data: "",
      metaTitle: SITE_TITLE,
      siteTitle: "",
      modules: null,
      related: null,
      prefix: "/" + this.$route.params.module,
      headerImage: "",
    };
  },
  metaInfo() {
    const mTitle = this.metaTitle;
    const mDescription = this.data.description;
    const mKeywords = this.data.keywords;
    return {
      title: mTitle + " | " + SITE_TITLE,
      meta: [
        {name: "description", content: mDescription},
        {name: "keywords", content: mKeywords},
      ],
      htmlAttrs: {
        lang: sessionStorage.lang ? sessionStorage.lang : 'hu'
      }
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  beforeCreate() {
    document.body.className = "bg-white";
  },
  methods: {
    async getData() {
      this.domain = window.location.href;
      await CmsApiService.getContent("article/" + this.$route.params.url).then(
          (response) => {
            if (response) {
              this.data = response.data.content;
              this.metaTitle = response.data.content.title;
              this.modules = response.data.modules;
              this.related = response.data.related;
              this.headerImage = this.$setHeaderImage(null);
            }
          }
      );
    },
    createDate(date, format) {
      return moment(date).format(format);
    },
  },
};
</script>

<style scoped></style>
